import type { EventRegistration } from '@/types/storyblok';
import { isUserRegisteredForEvent } from '@/api';
import { isUserLoggedIn } from '@/utils/user';
import moment from 'moment-timezone';

const timeZoneAbbreviationMap = {
  'Asia/Singapore': 'SGT',
  Singapore: 'SGT',
  'Asia/Riyadh': 'AST',
};
export enum EventRegistrationState {
  // registration flow
  EventRegistrationReady,
  EventRegistrationDone,
  // outside live window
  EventNotLiveYet,
  EventEnded,
  // special cases
  UserIsNotLoggedIn,
  UserIsAlreadyRegistered,
}
export function validateTimeZone(timezone): string {
  if (timezone && moment.tz.zone(timezone)) return timezone;
  return 'GMT+0';
}

export function timeZoneAbbreviation(timezone): string {
  if (timeZoneAbbreviationMap[timezone]) return timeZoneAbbreviationMap[timezone];
  return moment.tz(validateTimeZone(timezone)).zoneAbbr();
}

function getCurrentDateTime(timezone: string) {
  return moment.tz(validateTimeZone(timezone));
}

export async function getEventRegistrationState(
  user: API.UserInfo,
  id: string,
  data: EventRegistration
): Promise<EventRegistrationState> {
  const currentDateTime = getCurrentDateTime(data.timezone);
  const eventStartDateTime = moment.utc(data.startsAt).tz(validateTimeZone(data.timezone));
  const eventEndDateTime = moment.utc(data.endsAt).tz(validateTimeZone(data.timezone));
  if (eventStartDateTime.isAfter(currentDateTime)) return EventRegistrationState.EventNotLiveYet;
  if (eventEndDateTime.isBefore(currentDateTime)) return EventRegistrationState.EventEnded;
  if (!isUserLoggedIn(user)) return EventRegistrationState.UserIsNotLoggedIn;
  if (await isUserRegisteredForEvent(id)) {
    return EventRegistrationState.UserIsAlreadyRegistered;
  }
  return EventRegistrationState.EventRegistrationReady;
}
