<template>
  <StoryblokBackgroundImage
    id="event-registration-left-section"
    data-test-id="event-registration-left-section"
    class="w-[100%] md:w-[46%] flex justify-center bg-cover bg-top bg-no-repeat px-8 py-8 sm:px-[77px] sm:py-[53px]"
    :image="eventDetails.leftBackgroundImage"
  >
    <div
      class="h-fit w-auto flex flex-col bg-[#26044d] rounded-[36px] pt-[21px] pb-[42px] pl-[26px] pr-[26px] sm:pt-14 sm:pb-[64px] sm:pl-[43px] sm:pr-[38px]"
    >
      <h1
        class="mt-0 mb-0 text-4xl sm:text-5xl leading-[51px] sm:leading-[63px] font-medium title-bg bg-clip-text text-transparent"
      >
        {{ eventDetails.title }}
      </h1>
      <div class="mt-5 mb-5"><hr class="border-t-[1px] border-[#9941ff]" /></div>
      <div class="text-[#f8b9e7]">
        <h2 v-if="areSameDayMonthYear" class="mt-0 mb-0 text-2xl sm:text-4xl leading-[28px]">
          {{ moment.utc(eventDetails.endsAt).tz(validateTimeZone(eventDetails.timezone)).format('Do MMM YYYY') }}
        </h2>
        <h2 v-else-if="areSameMonth" class="mt-0 mb-0 text-2xl sm:text-4xl leading-[28px]">
          {{ moment.utc(eventDetails.startsAt).tz(validateTimeZone(eventDetails.timezone)).format('D') }} -
          {{ moment.utc(eventDetails.endsAt).tz(validateTimeZone(eventDetails.timezone)).format('Do MMM YYYY') }}
        </h2>
        <h2 v-else class="mt-0 mb-0 text-2xl sm:text-4xl leading-[28px]">
          {{ moment.utc(eventDetails.startsAt).tz(validateTimeZone(eventDetails.timezone)).format('D MMM') }} -
          {{ moment.utc(eventDetails.endsAt).tz(validateTimeZone(eventDetails.timezone)).format('Do MMM YYYY') }}
        </h2>
        <h2 v-if="isTimeSpecified" class="mt-2 sm:mt-[18px] mb-0 text-2xl sm:text-4xl leading-[28px]">
          {{ moment.utc(eventDetails.startsAt).tz(validateTimeZone(eventDetails.timezone)).format('ha') }} -
          {{ moment.utc(eventDetails.endsAt).tz(validateTimeZone(eventDetails.timezone)).format('ha') }}
          {{ timeZoneAbbreviation(eventDetails.timezone) }}
        </h2>
      </div>
    </div>
  </StoryblokBackgroundImage>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';
import type { EventRegistration } from '@/types/storyblok';
import moment from 'moment-timezone';
import { validateTimeZone, timeZoneAbbreviation } from '@/utils/eventRegistration';
import StoryblokBackgroundImage from '@/components/storyblok/StoryblokBackgroundImage.vue';

const props = defineProps({
  eventDetails: { type: Object as PropType<EventRegistration>, required: true },
});

const isTimeSpecified = computed(() => {
  return !(
    moment(props.eventDetails.startsAt).hours() === 0 &&
    moment(props.eventDetails.startsAt).minutes() === 0 &&
    moment(props.eventDetails.startsAt).seconds() === 0 &&
    moment(props.eventDetails.endsAt).hours() === 0 &&
    moment(props.eventDetails.endsAt).minutes() === 0 &&
    moment(props.eventDetails.endsAt).seconds() === 0
  );
});

const areSameDayMonthYear = computed(() => {
  return moment(props.eventDetails.startsAt).isSame(moment(props.eventDetails.endsAt), 'day');
});

const areSameMonth = computed(() => {
  return moment(props.eventDetails.startsAt).isSame(moment(props.eventDetails.endsAt), 'month');
});
</script>

<style scoped lang="scss">
.title-bg {
  background: linear-gradient(270deg, #d5279b 3.89%, #fea18c 96.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* For Safari/WebKit browsers */
}
</style>
